import { HEADER_HEIGHT } from "@/constants/components";
import { useActivePathCheck } from "@/hooks/useActivePathCheck";

import {
  colors,
  HStack,
  TabBar,
  GoiLogo,
  Spacing,
  Button,
  GLOBAL_MAX_WIDTH,
  NextLink,
  customEvent,
  EventTriggerAndTargetType,
  EventPathType,
  Hamburger,
  useResponsive,
  VStack,
  responsive,
} from "goi_common";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";
import { isLoggedInState } from "@/atoms/accountState";

import { ReactNode, useMemo } from "react";

import { Box } from "@chakra-ui/react";

export function Header({ onOpen }: { onOpen: () => void }) {
  const handleHamburgerClick = () => {
    customEvent({
      newGtm: {
        path: "gnb",
        trigger_and_target: "hamburger",
      },
    });
    onOpen();
  };

  const { isBiggerThanLg } = useResponsive();

  return (
    <header>
      <div
        css={{
          position: "fixed",
          width: "100%",
          top: "0",
          zIndex: 1000,
          backgroundColor: colors.white,
          borderBottom: `1px solid ${colors.gray100}`,
          transition: "none",
          userSelect: "none",
        }}
      >
        <VStack
          margin="0 auto"
          css={responsive({
            width: {
              base: "100%",
              lg: `${GLOBAL_MAX_WIDTH}px`,
            },
          })}
        >
          <HStack height={HEADER_HEIGHT}>
            <HStack
              padding="20px 20px"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <NextLink
                id="goi-logo"
                href="/"
                onClick={() => {
                  customEvent({
                    newGtm: {
                      path: "gnb",
                      trigger_and_target: "logo-uv_home",
                    },
                  });
                }}
              >
                <GoiLogo color={"black"} />
              </NextLink>
              <HStack alignItems="center">
                <div
                  css={responsive({
                    display: {
                      lg: "block",
                      base: "none",
                    },
                  })}
                >
                  <HeaderTabBar />
                </div>
                <Spacing orientation="horizontal" gap={36} />
                <HeaderButtons isHomeViewportTop={false} homePath="/" />

                <Spacing orientation="horizontal" gap={16} />
                <Box position="relative">
                  <button onClick={handleHamburgerClick}>
                    <Hamburger color={colors.gray700} />
                  </button>
                  <Box
                    position="absolute"
                    className="white"
                    width="12px"
                    height="12px"
                    borderRadius="50%"
                    background="red"
                    fontSize="8px"
                    textAlign="center"
                    verticalAlign="middle"
                    lineHeight="12px"
                    top="-5px"
                    right="-10px"
                  >
                    N
                  </Box>
                </Box>
              </HStack>
            </HStack>
          </HStack>
          <div
            css={responsive({
              display: {
                lg: "none",
                base: "block",
              },
            })}
          >
            <HeaderTabBar />
          </div>
          {/* <div className="lg_over_hidden">
            
          
          {!isBiggerThanLg && <HeaderTabBar />} */}
        </VStack>
      </div>

      <Spacing gap={isBiggerThanLg ? HEADER_HEIGHT : HEADER_HEIGHT + 40} />
    </header>
  );
}

function HeaderTabBar() {
  const { activePath } = useActivePathCheck();
  const { isBiggerThanLg } = useResponsive();

  const menus: { text: string; to: string; eventDetails: EventTriggerAndTargetType["gnb"][] }[] = [
    {
      text: "홈",
      to: "/",
      eventDetails: ["nav-uv_home"],
    },
    {
      text: "장례 견적",
      to: "/match/request/",
      eventDetails: ["nav-uv_match_request"],
    },
    {
      text: "가이드북",
      to: "/guidebook/",
      eventDetails: ["nav-uv_guidebook"],
    },
    {
      text: "고객 후기",
      to: "/review/",
      eventDetails: ["review-uv_review"],
    },
    {
      text: "24시간 전화",
      to: "tel:1666-9784",
      eventDetails: ["call-opn_call_mdl"],
    },
  ];

  return (
    <Box
      margin="0 10px"
      css={{
        overflow: "auto",
      }}
    >
      <TabBar gap={isBiggerThanLg ? 32 : 0} justifyContent="space-between" height="40px">
        {menus.map((item, i) => {
          const isActive = activePath === item.text;

          return (
            <Box position="relative" key={i}>
              <NextLink href={item.to}>
                <TabBar.Tab
                  className={`${
                    isActive ? "subtitle_16_sb md_subtitle_18_sb" : "body_16_m md_body_18_m"
                  } gray500`}
                  css={responsive({
                    padding: "6px 0px",
                    margin: "0 10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    borderBottom: {
                      base: isActive ? `4px solid ${colors.gray900}` : "",
                      lg: "none",
                    },
                    color: {
                      base: isActive ? colors.gray900 : "",
                    },
                  })}
                  onClick={() => {
                    item.eventDetails.map((trigger_and_target) => {
                      customEvent({
                        newGtm: {
                          path: "gnb",
                          trigger_and_target,
                        },
                      });
                    });
                  }}
                >
                  {item.text}
                </TabBar.Tab>
              </NextLink>
            </Box>
          );
        })}
      </TabBar>
    </Box>
  );
}

export function HeaderButtons({
  isHomeViewportTop,
  homePath,
}: {
  isHomeViewportTop: boolean;
  homePath: string;
}) {
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const router = useRouter();

  return (
    <>
      {isLoggedIn || (
        <div
          className="body3_m"
          css={{
            padding: "6px 12px",
            backgroundColor: isHomeViewportTop ? "transparent" : "white",
            color: isHomeViewportTop ? "white" : "black",
            border: `1px solid ${colors.gray200}`,
            borderRadius: "5px",
            marginRight: "8px",
            tansition: "all 0.1s linear",
          }}
          onClick={() => {
            customEvent({
              newGtm: {
                path: "gnb",
                trigger_and_target: "login-uv_login",
              },
            });
            router.push(`/login?redirectTo=${encodeURIComponent(router.asPath)}`);
          }}
        >
          로그인
        </div>
      )}
      {isLoggedIn ? (
        <HeaderBtn
          type="BLACK"
          onClick={() => {
            router.push("/step-guide/initiation/");
          }}
          eventTrigger="step-guide"
        >
          장례준비 가이드
        </HeaderBtn>
      ) : (
        <HeaderBtn
          type="ORANGE"
          onClick={() => {
            router.push("/funeral-prepare/1/");
          }}
          eventTrigger="srvc_rcmms-uv_funeral_prepare_1"
        >
          장례가 처음이라면?
        </HeaderBtn>
      )}
    </>
  );
}

function HeaderBtn({
  type,
  eventTrigger,
  onClick,
  children,
}: {
  type: string;
  eventTrigger: EventTriggerAndTargetType[EventPathType];
  onClick: () => void;
  children: ReactNode;
}) {
  const btnCss = useMemo(() => {
    if (type === "ORANGE") {
      return {
        padding: "6px 12px",
        backgroundColor: colors.orange650,
        color: "white",
        borderRadius: "5px",
      };
    } else if (type === "BLACK") {
      return {
        padding: "6px 12px",
        backgroundColor: colors.black,
        color: "white",
        borderRadius: "5px",
      };
    }
  }, [type]);

  return (
    <Button
      className="body3_m"
      css={btnCss}
      onClick={() => {
        customEvent({
          newGtm: {
            path: "gnb",
            trigger_and_target: eventTrigger,
          },
        });
        onClick();
      }}
    >
      {children}
    </Button>
  );
}
